/**
 * @generated SignedSource<<43847296742615e4f6fb0d2d6798895a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UploadStatusLabel_SettingsUI_SubscriberUploadJob$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UploadStatusLabel_getStatusMetaData_SettingsUI_SubscriberUploadJob">;
  readonly " $fragmentType": "UploadStatusLabel_SettingsUI_SubscriberUploadJob";
};
export type UploadStatusLabel_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: UploadStatusLabel_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadStatusLabel_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadStatusLabel_SettingsUI_SubscriberUploadJob",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "UploadStatusLabel_getStatusMetaData_SettingsUI_SubscriberUploadJob",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "SubscriberUploadJob",
  "abstractKey": null
};

(node as any).hash = "d8ca7bb1cd85fcc59002b68c7cfe6e85";

export default node;
